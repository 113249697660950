import { NbMenuItem } from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: "Companies",
    icon: "ion-android-home",
    link: "/pages/companies",
    pathMatch: "full",
  },
  {
    title: "Dashboard",
    icon: "ion-android-home",
    link: "/pages/dashboard",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Billing Reports",
    icon: "ion-android-home",
    link: "/pages/billing-reports",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Media",
    icon: "nb-play",
    link: "/pages/media",
    pathMatch: "full",
  },
  {
    title: "Playlists",
    icon: "nb-list",
    link: "/pages/playlist",
    pathMatch: "full",
  },
  {
    title: "Channels",
    icon: "nb-angle-double-right",
    link: "/pages/channel",
    pathMatch: "full",
  },

  {
    title: "Players",
    icon: "nb-shuffle",
    link: "/pages/players",
    pathMatch: "full",
  },

  {
    title: "Admin",
    icon: "nb-angle-double-right",
    link: "/pages/admin",
    pathMatch: "full",
  },
];

export const TEMP_MENU_ITEMS: NbMenuItem[] = [
  {
    title: "Companies",
    icon: "ion-android-home",
    link: "/pages/companies",
    pathMatch: "full",
  },
  {
    title: "Dashboard",
    icon: "ion-android-home",
    link: "/pages/dashboard",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Billing Reports",
    icon: "ion-android-home",
    link: "/pages/billing-reports",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Media",
    icon: "nb-play",
    link: "/pages/media",
    pathMatch: "full",
  },
  {
    title: "Playlists",
    icon: "nb-list",
    link: "/pages/playlist",
    pathMatch: "full",
  },
  {
    title: "Channels",
    icon: "nb-angle-double-right",
    link: "/pages/channel",
    pathMatch: "full",
  },
  {
    title: "Players",
    icon: "nb-shuffle",
    link: "/pages/players",
    pathMatch: "full",
  },
  {
    title: "Admin",
    icon: "nb-angle-double-right",
    link: "/pages/admin",
    pathMatch: "full",
  },

];
