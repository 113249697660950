export class CompanyModel {
  id: string;
  name: string;
  username: string;
  email: string;
  playerLimit: number;
  pass: string;
  confirmPass: string;
  picURL: string;
  path: string;
  isActive: boolean;
  superAdmin: boolean;
  companyExpireDate?: string;
  pricingInfo: CompanyPricingModel;


  constructor() {
    this.id = '';
    this.name = '';
    this.username = '';
    this.email = '';
    this.playerLimit = 1;
    this.pass = '';
    this.confirmPass = '';
    this.picURL = '';
    this.path = '';
    this.isActive = false;
    this.superAdmin = false;
    this.pricingInfo = {
      type: 'fixed',
      fixedPrice: 0,
      screenPrices: [],
      priceRanges: []
    }
  }
}

export class CompanyPricingModel {
  type: string;
  fixedPrice: number;
  screenPrices: number[];
  priceRanges: { [key: string]: number }[];

  constructor() {
    this.type = 'fixed';
    this.fixedPrice = 0;
    this.screenPrices = [];
    this.priceRanges = [];
  }
}
